var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _vm.status === "PENDING"
            ? _c("b-col", { attrs: { cols: "auto" } }, [
                _vm._v(" Please wait, we are dealing with your request... "),
              ])
            : _vm.status === "DONE"
            ? _c(
                "b-col",
                { attrs: { cols: "auto" } },
                [
                  _vm._v(" Done! Your email has been verified. "),
                  _c("b-link", { attrs: { to: "/" } }, [_vm._v("Click here")]),
                  _vm._v(" to login. "),
                ],
                1
              )
            : _c("b-col", { attrs: { cols: "auto" } }, [
                _vm._v(
                  " An error occurred, please check you copied the link correctly."
                ),
                _c("br"),
                _vm._v(" If the problem persists, contact support. "),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }